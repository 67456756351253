import { Component, Input } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VideoPlayerDetailsComponent } from './video-player-details/video-player-details.component';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  providers: [DialogService],
})
export class VideoPlayerComponent {
  @Input() videoUrl: string = '';
  @Input() height: number = 0;
  @Input() width: number = 0;
  @Input() iconHeight: number = 35;
  @Input() thumbnailUrl: string = '';

  showVideo: boolean = false;
  showedVideoUrl: string = '';

  ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService){}
  
  onClickVideo(){
    this.ref = this.dialogService.open(VideoPlayerDetailsComponent, {
      showHeader: true,
      contentStyle: {'max-height': '80vh', 'max-width': '90vw'},
      data: {
        videoUrl : this.videoUrl
      }
    })

    /*
    console.log('clicked video');
    this.showVideo = true;
    this.showedVideoUrl = this.videoUrl;
    */
  }
}
