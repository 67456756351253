import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyQmRequest } from '../shared/models/myqm-request.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private apiUrl = environment.API_URL;
  private loading: boolean = false;

  showLoader(show: boolean) {
    this.loading = show;
  }

  isLoading() {
    return this.loading;
  }

  uploadFile(file: File) {
    let formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/common/uploadFile`, formData)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
