import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformMinutes'
})
export class TransformMinutesPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const minutes: number = Math.floor(value / 60);

    return minutes;
  }
}

@Pipe({
  name: 'transformSeconds'
})
export class TransformSecondsPipe implements PipeTransform {
  // value must be in seconds
  transform(value: number): number {
    const seconds: number = value % 60;

    return seconds;
  }
}