import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-media-list',
  templateUrl: './social-media-list.component.html',
  styleUrls: ['./social-media-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialMediaListComponent {

  readonly ENVIRONMENT = environment;

  @Input() showHeader: boolean = true;
  @Input() normalList: boolean;   // optional, default is false

  constructor() {
    this.normalList = false;
  }

  routeTo(url: string) {
    window.location.href = url;
  }
}
