<div [attr.id]="id" [ngClass]="{ 'p-carousel p-component': true, 'p-carousel-vertical': isVertical(), 'p-carousel-horizontal': !isVertical() }" [ngStyle]="style" [class]="styleClass" role="region">
    <div class="p-carousel-header" *ngIf="headerFacet || headerTemplate">
        <ng-content select="p-header"></ng-content>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div [class]="contentClass" [ngClass]="'p-carousel-content'">
        <div class="p-carousel-container" [attr.aria-live]="allowAutoplay ? 'polite' : 'off'">
            <button
                type="button"
                *ngIf="showNavigators"
                [ngClass]="{ 'p-carousel-prev p-link': true, 'p-disabled': isBackwardNavDisabled() }"
                [disabled]="isBackwardNavDisabled()"
                [attr.aria-label]="ariaPrevButtonLabel()"
                (click)="navBackward($event)"
                pRipple
            >
                <ng-container *ngIf="!previousIconTemplate">
                    <ChevronLeftIcon *ngIf="!isVertical()" [styleClass]="'carousel-prev-icon'" />
                    <ChevronUpIcon *ngIf="isVertical()" [styleClass]="'carousel-prev-icon'" />
                </ng-container>
                <span *ngIf="previousIconTemplate" class="p-carousel-prev-icon">
                    <ng-template *ngTemplateOutlet="previousIconTemplate"></ng-template>
                </span>
            </button>
            <div class="p-carousel-items-content" [ngStyle]="{ height: isVertical() ? verticalViewPortHeight : 'auto' }" (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
                <div #itemsContainer class="p-carousel-items-container" (transitionend)="onTransitionEnd()">
                    <div
                        *ngFor="let item of clonedItemsForStarting; let index = index"
                        [ngClass]="{
                            'p-carousel-item p-carousel-item-cloned': true,
                            'p-carousel-item-active': totalShiftedItems * -1 === value.length,
                            'p-carousel-item-start': 0 === index,
                            'p-carousel-item-end': clonedItemsForStarting!.length - 1 === index
                        }"
                        [attr.aria-hidden]="!(totalShiftedItems * -1 === value.length)"
                        [attr.aria-label]="ariaSlideNumber(index)"
                        [attr.aria-roledescription]="ariaSlideLabel()"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                    <div
                        *ngFor="let item of value; let index = index"
                        [ngClass]="{ 'p-carousel-item': true, 'p-carousel-item-active': firstIndex() <= index && lastIndex() >= index, 'p-carousel-item-start': firstIndex() === index, 'p-carousel-item-end': lastIndex() === index }"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                    <div
                        *ngFor="let item of clonedItemsForFinishing; let index = index"
                        [ngClass]="{
                            'p-carousel-item p-carousel-item-cloned': true,
                            'p-carousel-item-active': totalShiftedItems * -1 === numVisible,
                            'p-carousel-item-start': 0 === index,
                            'p-carousel-item-end': clonedItemsForFinishing!.length - 1 === index
                        }"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                </div>
            </div>
            <button
                type="button"
                *ngIf="showNavigators"
                [ngClass]="{ 'p-carousel-next p-link': true, 'p-disabled': isForwardNavDisabled() }"
                [disabled]="isForwardNavDisabled()"
                (click)="navForward($event)"
                pRipple
                [attr.aria-label]="ariaNextButtonLabel()"
            >
                <ng-container *ngIf="!nextIconTemplate">
                    <ChevronRightIcon *ngIf="!isVertical()" [styleClass]="'carousel-prev-icon'" />
                    <ChevronDownIcon *ngIf="isVertical()" [styleClass]="'carousel-prev-icon'" />
                </ng-container>
                <span *ngIf="nextIconTemplate" class="p-carousel-prev-icon">
                    <ng-template *ngTemplateOutlet="nextIconTemplate"></ng-template>
                </span>
            </button>
        </div>
        <ul #indicatorContent [ngClass]="'p-carousel-indicators p-reset'" [class]="indicatorsContentClass" [ngStyle]="indicatorsContentStyle" *ngIf="showIndicators" (keydown)="onIndicatorKeydown($event)">
            <li *ngFor="let totalDot of totalDotsArray(); let i = index" [ngClass]="{ 'p-carousel-indicator': true, 'p-highlight': _page === i }" [attr.data-pc-section]="'indicator'">
                <button
                    type="button"
                    [ngClass]="'p-link'"
                    (click)="onDotClick($event, i)"
                    [class]="indicatorStyleClass"
                    [ngStyle]="indicatorStyle"
                    [attr.aria-label]="ariaPageLabel(i + 1)"
                    [attr.aria-current]="_page === i ? 'page' : undefined"
                    [tabindex]="_page === i ? 0 : -1"
                ></button>
            </li>
        </ul>
    </div>
    <div class="p-carousel-footer" *ngIf="footerFacet || footerTemplate">
        <ng-content select="p-footer"></ng-content>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
</div>

<!--
<div [attr.id]="id" [ngClass]="{ 'p-carousel p-component': true, 'p-carousel-vertical': isVertical(), 'p-carousel-horizontal': !isVertical() }" [ngStyle]="style" [class]="styleClass" role="region">
    <div class="p-carousel-header" *ngIf="headerFacet || headerTemplate">
        <ng-content select="p-header"></ng-content>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div [class]="contentClass" [ngClass]="'p-carousel-content'">
        <div class="p-carousel-container" [attr.aria-live]="allowAutoplay ? 'polite' : 'off'">
            <button
                type="button"
                *ngIf="showNavigators"
                [ngClass]="{ 'p-carousel-prev p-link': true, 'p-disabled': isBackwardNavDisabled() }"
                [disabled]="isBackwardNavDisabled()"
                [attr.aria-label]="ariaPrevButtonLabel()"
                (click)="navBackward($event)"
                pRipple
            >
                <ng-container *ngIf="!previousIconTemplate">
                    <ChevronLeftIcon *ngIf="!isVertical()" [styleClass]="'carousel-prev-icon'" />
                    <ChevronUpIcon *ngIf="isVertical()" [styleClass]="'carousel-prev-icon'" />
                </ng-container>
                <span *ngIf="previousIconTemplate" class="p-carousel-prev-icon">
                    <ng-template *ngTemplateOutlet="previousIconTemplate"></ng-template>
                </span>
            </button>
            <div class="p-carousel-items-content" [ngStyle]="{ height: isVertical() ? verticalViewPortHeight : 'auto' }"  (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
                <div #itemsContainer class="p-carousel-items-container" (transitionend)="onTransitionEnd()">
                    <div
                        *ngFor="let item of clonedItemsForStarting; let index = index"
                        [ngClass]="{
                            'p-carousel-item p-carousel-item-cloned': true,
                            'p-carousel-item-active': totalShiftedItems * -1 === value.length,
                            'p-carousel-item-start': 0 === index,
                            'p-carousel-item-end': clonedItemsForStarting!.length - 1 === index
                        }"
                        [attr.aria-hidden]="!(totalShiftedItems * -1 === value.length)"
                        [attr.aria-label]="ariaSlideNumber(index)"
                        [attr.aria-roledescription]="ariaSlideLabel()"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                    <div
                        *ngFor="let item of value; let index = index"
                        [ngClass]="{ 'p-carousel-item': true, 'p-carousel-item-active': firstIndex() <= index && lastIndex() >= index, 'p-carousel-item-start': firstIndex() === index, 'p-carousel-item-end': lastIndex() === index }"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                    <div
                        *ngFor="let item of clonedItemsForFinishing; let index = index"
                        [ngClass]="{
                            'p-carousel-item p-carousel-item-cloned': true,
                            'p-carousel-item-active': totalShiftedItems * -1 === numVisible,
                            'p-carousel-item-start': 0 === index,
                            'p-carousel-item-end': clonedItemsForFinishing!.length - 1 === index
                        }"
                    >
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
                    </div>
                </div>
            </div>
            <button
                type="button"
                *ngIf="showNavigators"
                [ngClass]="{ 'p-carousel-next p-link': true, 'p-disabled': isForwardNavDisabled() }"
                [disabled]="isForwardNavDisabled()"
                (click)="navForward($event)"
                pRipple
                [attr.aria-label]="ariaNextButtonLabel()"
            >
                <ng-container *ngIf="!nextIconTemplate">
                    <ChevronRightIcon *ngIf="!isVertical()" [styleClass]="'carousel-prev-icon'" />
                    <ChevronDownIcon *ngIf="isVertical()" [styleClass]="'carousel-prev-icon'" />
                </ng-container>
                <span *ngIf="nextIconTemplate" class="p-carousel-prev-icon">
                    <ng-template *ngTemplateOutlet="nextIconTemplate"></ng-template>
                </span>
            </button>
        </div>
        <ul #indicatorContent [ngClass]="'p-carousel-indicators p-reset'" [class]="indicatorsContentClass" [ngStyle]="indicatorsContentStyle" *ngIf="showIndicators" (keydown)="onIndicatorKeydown($event)">
            <li *ngFor="let totalDot of totalDotsArray(); let i = index" [ngClass]="{ 'p-carousel-indicator': true, 'p-highlight': _page === i }" [attr.data-pc-section]="'indicator'">
                <button
                    type="button"
                    [ngClass]="'p-link'"
                    (click)="onDotClick($event, i)"
                    [class]="indicatorStyleClass"
                    [ngStyle]="indicatorStyle"
                    [attr.aria-label]="ariaPageLabel(i + 1)"
                    [attr.aria-current]="_page === i ? 'page' : undefined"
                    [tabindex]="_page === i ? 0 : -1"
                ></button>
            </li>
        </ul>
    </div>
    <div class="p-carousel-footer" *ngIf="footerFacet || footerTemplate">
        <ng-content select="p-footer"></ng-content>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
</div>
-->