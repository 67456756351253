<div class="flex justify-content-center align-items-center surface-100 relative" [ngStyle]="{
        height: height === 0 ? '100%' : height + 'px',
        width: width === 0 ? '100%' : width + 'px'
    }">

    <ng-container *ngIf="thumbnailUrl !== ''">
        <img [src]="thumbnailUrl" class="w-full">
    </ng-container>

    <ng-container *ngIf="thumbnailUrl === '' && (height === 0 || width === 0)">
        <video [src]="videoUrl" [autoplay]="false" muted class="opacity-60" playsinline
            style="width: 100%; height: 100%;"></video>
    </ng-container>

    <ng-container *ngIf="thumbnailUrl === '' && (height !== 0 && width !== 0)">
        <video [src]="videoUrl" [height]="height" [width]="width" [autoplay]="false" muted class="opacity-60"
            playsinline></video>
    </ng-container>

    <div class="z-1 w-full h-full absolute flex align-items-center justify-content-center cursor-pointer"
        (click)="onClickVideo()">
        <img src="./assets/images/icon/play_btn.svg" [height]="iconHeight">
    </div>
</div>

