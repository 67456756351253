import { Component, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RoutingService } from './services/routing.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'mmart';
  client = window.innerWidth < 768 ? 'mobile' : 'desktop';

  constructor(private router: Router, private routingService: RoutingService) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe(() => {
      const navigation = this.router.getCurrentNavigation();
      const isNavigatingBack = navigation?.trigger === 'popstate';
      this.routingService.isNavigatingBack = isNavigatingBack;
    });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      // Special handling for Reuse route primeng
      let divSidebarMask = document.querySelector('.p-sidebar-mask') as HTMLDivElement;
      divSidebarMask && divSidebarMask.click();

      document.body.classList.remove('p-overflow-hidden');
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    if (!this.isMobileDevice()) {
      if (this.client === 'mobile' && event.target.innerWidth >= 768) {
        window.location.reload();
      } else if (this.client === 'desktop' && event.target.innerWidth < 768) {
        window.location.reload();
      }
    }
  }

  isMobileDevice() {
    if (/Android/i.test(navigator.userAgent)) {
      return true;
    } else if (/iPhone/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }
}
