import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../shared/models/user.model';
import { MyQmRequest } from '../shared/models/myqm-request.model';
import { SearchCriteria } from '../shared/models/search-criteria.model';
import { Router } from '@angular/router';
import {
  InvitationInfo,
  InvitationRecords,
} from '../shared/models/invitation.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.API_URL;

  private userLoginSubject: BehaviorSubject<boolean>;
  public userLogin$: Observable<boolean>;

  public shouldReloadWishlist: boolean = false; // indicator to reload wishlist
  private wishlistItems: any[] = [];
  private wishlistItemsSubject: BehaviorSubject<any>;
  public wishlistItem$: Observable<any>;

  setClient(client: string){
    localStorage.setItem('client', client);
  }

  getClient(){
    return localStorage.getItem('client') || '';
  }

  setUserToken(token: string) {
    localStorage.setItem('mmart_token', token);
    this.userLoginSubject.next(true);
  }

  getUserToken() {
    return localStorage.getItem('mmart_token');
  }

  isUserLogin(): boolean {
    return localStorage.getItem('mmart_token') !== null;
  }

  routeToLogin() {
    let ssoUrl = new URL(environment.SSO_URL);
    ssoUrl.searchParams.append('source', 'mall');
    ssoUrl.searchParams.append(
      'lang',
      localStorage.getItem('lang') === 'ms' ? 'ms' : 'en'
    );
    ssoUrl.searchParams.append('icRequired', 'yes');
    
    let callbackUrl = location.origin + environment.SSO_CALLBACK_URL + '?redirectUrl=' + this.router.url;
    ssoUrl.searchParams.append('callbackUrl', encodeURIComponent(callbackUrl));
    location.href = ssoUrl.href;
  }

  logout() {
    localStorage.removeItem('mmart_token');
    this.userLoginSubject.next(false);
    window.location.reload();
  }

  getUserInfo() {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/getInfo`, {})
      .pipe(
        map((res): User => {
          res.data.phone = this.formatPhone(res.data.phone);
          return res.data;
        }),
        catchError((err) => {
          console.log(err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  formatPhone(phone: string) {
    return phone.substring(0, 2) + '-' + phone.substring(2, 5) + ' ' + phone.substring(5, phone.length);
  }

  /**
   * @deprecated
   * @param searchCriteria 
   * @returns 
   */
  getUserWishlist(searchCriteria: SearchCriteria) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/user/wishlist/`,
        searchCriteria
      )
      .pipe(
        map((res) => {
          //For Header Use
          if (JSON.stringify(searchCriteria) === '{}') {
            this.wishlistItemsSubject.next(res.data.totalNumber || 0);
          }
          return res.data;
        })
      );
  }

  addUserWishlist(commodityIds: number[]) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/wishlist/add`, {
        commodityIds: commodityIds,
      })
      .pipe(
        map((res) => {
          this.shouldReloadWishlist = true;
          return res.code === 200;
        })
      );
  }

  removeUserWishlist(commodityIds: number[]) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/wishlist/remove`, {
        commodityIds: commodityIds,
      })
      .pipe(
        map((res) => {
          this.shouldReloadWishlist = true;
          return res.code === 200;
        })
      );
  }

  getDashboard() {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/dashboard`, {})
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getInvitationInfo() {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/invitation/getInfo`, {})
      .pipe(
        map((res) => {
          return res.data as InvitationInfo;
        })
      );
  }

  getInvitationTerms() {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/invitation/getInfo`, {})
      .pipe(
        map((res) => {
          let data = res.data as InvitationInfo;
          return data.reward.ruleDescription;
        })
      );
  }

  getInvitationRecords(pageNo: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/user/invitation/getRecords`, {
        pageNo: pageNo,
      })
      .pipe(
        map((res) => {
          return res.data as InvitationRecords;
        })
      );
  }

  getUserWishlistV2(searchCriteria: SearchCriteria) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v2/user/wishlist/`,
        searchCriteria
      )
      .pipe(
        map((res) => {
          //For Header Use
          if (JSON.stringify(searchCriteria) === '{}') {
            this.wishlistItemsSubject.next(res.data.totalNumber || 0);
          }
          return res.data;
        })
      );
  }

  constructor(private http: HttpClient, private router: Router) {
    this.userLoginSubject = new BehaviorSubject<boolean>(this.isUserLogin());
    this.userLogin$ = this.userLoginSubject.asObservable();

    this.wishlistItemsSubject = new BehaviorSubject<any>(this.wishlistItems);
    this.wishlistItem$ = this.wishlistItemsSubject.asObservable();
  }
}
