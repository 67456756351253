<div class="flex flex-column">
    <span class="font-semibold" *ngIf="showHeader" [ngClass]="normalList ? 'text-n900' : 'text-primary'">{{ 'SocialMedia.FindUsOn' | translate }}</span>
    <div class="flex gap-3 mt-2">
        <div class="w-2rem h-2rem border-circle border-1 flex justify-content-center align-items-center cursor-pointer" [ngClass]="normalList ? 'border-white' : 'border-p300'" (click)="routeTo(ENVIRONMENT.FACEBOOK_URL)">
            <ng-container *ngIf="normalList else footerFacebook">
                <img src="./assets/images/icon/facebook.svg" height="30">
            </ng-container>
            <ng-template #footerFacebook>
                <img src="./assets/images/icon/facebook_gold.svg" height="15">
            </ng-template>
        </div>
        <div class="w-2rem h-2rem border-circle border-1 flex justify-content-center align-items-center cursor-pointer" [ngClass]="normalList ? 'border-white' : 'border-p300'" (click)="routeTo(ENVIRONMENT.TWITTER_URL)">
            <ng-container *ngIf="normalList else footerTwitter">
                <img src="./assets/images/icon/twitter.svg" height="30">
            </ng-container>
            <ng-template #footerTwitter>
                <img src="./assets/images/icon/twitter_gold.svg" height="15">
            </ng-template>
        </div>
        <div class="w-2rem h-2rem border-circle border-1 flex justify-content-center align-items-center cursor-pointer" [ngClass]="normalList ? 'border-white' : 'border-p300'" (click)="routeTo(ENVIRONMENT.INSTAGRAM_URL)">
            <ng-container *ngIf="normalList else footerInstagram">
                <img src="./assets/images/icon/instagram.svg" height="30">
            </ng-container>
            <ng-template #footerInstagram>
                <img src="./assets/images/icon/instagram_gold.svg" height="15">
            </ng-template>
        </div>
        <div class="w-2rem h-2rem border-circle border-1 flex justify-content-center align-items-center cursor-pointer" [ngClass]="normalList ? 'border-white' : 'border-p300'" (click)="routeTo(ENVIRONMENT.YOUTUBE_URL)">
            <ng-container *ngIf="normalList else footerYoutube">
                <img src="./assets/images/icon/youtube.svg" height="30">
            </ng-container>
            <ng-template #footerYoutube>
                <img src="./assets/images/icon/youtube_gold.svg" height="15">
            </ng-template>
        </div>
    </div>
</div>
