import { Component, Input } from '@angular/core';
import { OrderSummary } from 'src/app/shared/models/order.model';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent {

  @Input() isShoppingCartPage: boolean; // optional, default is false
  @Input() isCheckoutPage: boolean; // optional, default is false
  @Input() isOrderDetailsPage: boolean; // optional, default is false
  @Input() isOrderReceiptPage: boolean; // optional, default is false
  @Input() isMobile: boolean; // optional, default is false
  @Input() orderSummary: OrderSummary;

  constructor() {
    this.isShoppingCartPage = false;
    this.isCheckoutPage = false;
    this.isOrderDetailsPage = false;
    this.isOrderReceiptPage = false;
    this.isMobile = false;
    this.orderSummary = new OrderSummary();
  }

  ngOnInit() {

  }
}
