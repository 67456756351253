import { Injectable } from '@angular/core';
import JSEncrypt from 'jsencrypt';
import { IJSEncryptOptions } from 'jsencrypt/lib/JSEncrypt';
import { hex2b64 } from 'jsencrypt/lib/lib/jsbn/base64';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  encryptData(data: string) {
    const rsa = new JSEncryptExt();
    rsa.setPublicKey(environment.PUBLIC_KEY);
    let encrypted = rsa.encrypt(data);
    return encrypted;
  }

  constructor() { }
}

/**
 * Custom JSEncrypt class to solve long data issue
 */
class JSEncryptExt extends JSEncrypt {
  constructor(options?: IJSEncryptOptions) {
    super(options);
  }

  override encrypt(str: string): string | false {
    try {
      const maxByteLength: number = 117;
      let i: number = 0;
      let byteArr: (string | null)[] = [];
      while (i <= str.length - 1) {
        const c: number = str.charCodeAt(i);
        if (c < 128) {
          byteArr.push(str[i]);
        } else if (c > 127 && c < 2848) {
          byteArr.push(null, str[i]);
        } else {
          byteArr.push(null, null, str[i]);
        }
        i++;
      }
      if (byteArr.length <= maxByteLength) {
        return hex2b64(this.getKey().encrypt(str));
      } else {
        // long plain text encrypt
        let cipherStrSum: string = '';
        while (byteArr.length > 0) {
          let offset: number = maxByteLength;
          while (byteArr[offset - 1] === null) {
            offset = offset - 1;
          }
          const text: string = byteArr
            .slice(0, offset)
            .filter((i) => i !== null)
            .join('');
          cipherStrSum += this.getKey().encrypt(text);
          byteArr.splice(0, offset);
        }
        return hex2b64(cipherStrSum);
      }
    } catch (error) {
      return false;
    }
  }
}
