import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent {
  @Input() source : string | undefined;

  @ViewChild('audio', { static: true }) audioElement!: ElementRef<HTMLAudioElement>;

  isPlaying: boolean = false;

  progress = 0;
  currentTime = 0;
  duration = 0;

  ngOnInit(): void {
    const audio = this.audioElement.nativeElement;

    audio.addEventListener('loadedmetadata', () => {
      this.duration = audio.duration * 1000; // Convert to milliseconds for the date pipe
    });

    audio.addEventListener('timeupdate', () => {
      this.currentTime = audio.currentTime * 1000; // Convert to milliseconds for the date pipe
      this.progress = (audio.currentTime / audio.duration) * 100;

      if(this.progress === 100){
        this.isPlaying = false;
      }
    });
  }

  play(): void {
    this.isPlaying = true;
    this.audioElement.nativeElement.play();
  }

  pause(): void {
    this.isPlaying = false;
    this.audioElement.nativeElement.pause();
  }

  stop(): void {
    this.isPlaying = false;
    const audio = this.audioElement.nativeElement;
    audio.pause();
    audio.currentTime = 0;
    this.currentTime = 0;
    this.progress = 0;
  }

  seek(event: MouseEvent): void {
    const progressBar = event.currentTarget as HTMLElement;
    const clickPosition = event.offsetX / progressBar.clientWidth;
    this.audioElement.nativeElement.currentTime = clickPosition * this.audioElement.nativeElement.duration;
  }
}
