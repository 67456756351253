import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-video-player-details',
  templateUrl: './video-player-details.component.html',
  styleUrls: ['./video-player-details.component.scss']
})
export class VideoPlayerDetailsComponent {
  videoUrl: string = '';

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.videoUrl = this.config.data.videoUrl;
  }
  
  closeDialog() {
    this.ref.close();
  }
}
