import {
  ActivatedRouteSnapshot,
  RouteReuseStrategy,
  DetachedRouteHandle,
  UrlSegment,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { RoutingService } from 'src/app/services/routing.service';

interface RouteStorageObject {
  snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  back: boolean = false;
  constructor(private routingService: RoutingService) {
  }

  storedRoutes: { [key: string]: RouteStorageObject } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    //return true;
    return route.data['reuse'] || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const id = this.createIdentifier(route);
    if (route.data['reuse'] && id.length > 0) {
      //if (id.length > 0) {
      this.storedRoutes[id] = { handle, snapshot: route };
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const id = this.createIdentifier(route);
    const storedObject = this.storedRoutes[id];
    const canAttach = !!route.routeConfig && !!storedObject;

    if (!canAttach || !this.routingService.isNavigatingBack) {
      return false;
    }

    const paramsMatch = this.compareObjects(
      route.params,
      storedObject.snapshot.params
    );
    const queryParamsMatch = this.compareObjects(
      route.queryParams,
      storedObject.snapshot.queryParams
    );

    return paramsMatch && queryParamsMatch;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const id = this.createIdentifier(route);
    if (!route.routeConfig || !this.storedRoutes[id]) return {};
    return this.storedRoutes[id].handle;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private createIdentifier(route: ActivatedRouteSnapshot) {
    // Build the complete path from the root to the input route
    const segments: UrlSegment[][] = route.pathFromRoot.map((r) => r.url);
    const subpaths = ([] as UrlSegment[])
      .concat(...segments)
      .map((segment) => segment.path);
    // Result: ${route_depth}-${path}
    return segments.length + '-' + subpaths.join('/');
  }

  private compareObjects(base: any, compare: any): boolean {
    // loop through all properties
    for (const baseProperty in { ...base, ...compare }) {
      // determine if comparrison object has that property, if not: return false
      if (compare.hasOwnProperty(baseProperty)) {
        switch (typeof base[baseProperty]) {
          // if one is object and other is not: return false
          // if they are both objects, recursively call this comparison function
          case 'object':
            if (
              typeof compare[baseProperty] !== 'object' ||
              !this.compareObjects(base[baseProperty], compare[baseProperty])
            ) {
              return false;
            }
            break;
          // if one is function and other is not: return false
          // if both are functions, compare function.toString() results
          case 'function':
            if (
              typeof compare[baseProperty] !== 'function' ||
              base[baseProperty].toString() !== compare[baseProperty].toString()
            ) {
              return false;
            }
            break;
          // otherwise, see if they are equal using coercive comparison
          default:
            // tslint:disable-next-line triple-equals
            if (base[baseProperty] != compare[baseProperty]) {
              return false;
            }
        }
      } else {
        return false;
      }
    }

    // returns true only after false HAS NOT BEEN returned through all loops
    return true;
  }
}
