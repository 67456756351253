import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
})
export class SsoLoginComponent {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {
    this.userService.setUserToken(
      this.route.snapshot.queryParamMap.get('token') || ''
    );

    this.userService.setClient(
      this.route.snapshot.queryParamMap.get('client') || ''
    )

    let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl') || '/';
    console.log(redirectUrl);
    this.router.navigateByUrl(redirectUrl, {replaceUrl: true});
  }
}
