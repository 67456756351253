<div class="audio-player flex align-items-center shadow-2">
    <audio #audio>
        <source *ngIf="source" [src]="source" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>

    <div class="flex gap-2">
        <i class="pi pi-play cursor-pointer" *ngIf="!isPlaying" (click)="play()"></i>
        <i class="pi pi-pause cursor-pointer" *ngIf="isPlaying" (click)="pause()"></i>
        <i class="pi pi-stop-circle cursor-pointer" (click)="stop()"></i>
    </div>

    <div class="progress-bar ml-3" (click)="seek($event)">
        <div class="progress" [style.width.%]="progress"></div>
    </div>

    <div class="flex text-sm ml-3 justify-content-end" style="min-width: 80px;">
        <span>{{ currentTime | date: 'mm:ss': 'UTC' }}</span> / <span>{{ duration | date: 'mm:ss': 'UTC' }}</span>
    </div>
</div>
