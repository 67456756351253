import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from 'src/app/shared/models/notification.model';
import {
  OrderStatusList,
  OrderSubstatusList,
} from 'src/app/shared/models/order.model';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent {
  @Input() notification!: Notification;
  @Input() isClamped: boolean = false; //Clamped notification content

  readNotificationSub: Subscription | undefined;

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) { }

  onNotificationClick() {
    this.readNotification();
  }

  readNotification() {
    this.readNotificationSub = this.notificationService
      .readNotification(this.notification.id || 0)
      .subscribe((res) => {
        this.notification.readStatus = 'Y';
        this.getNotificationCount();
        this.routeNotification();
      });
  }

  getNotificationCount() {
    this.notificationService.getNotificationsCount().subscribe();
  }

  routeNotification() {
    if (this.notification.type === 'order') {
      this.router.navigate([
        `/profile/order-list/details/${this.notification.orderId}`,
      ]);
    } else if (this.notification.type === 'promo') {
      this.router.navigate([`/promo/${this.notification.noticeId}`]);
    } else if (this.notification.type === 'shoppingCart') {
      this.router.navigate(['/cart/cart-list']);
    } else if (this.notification.type === 'wishlist') {
      this.router.navigate(['/wishlist']);
    }
  }

  getOrderStatusText(status: string) {
    type objKey = keyof typeof OrderSubstatusList;
    let statusKey = status as objKey;
    console.log(statusKey);

    if (OrderSubstatusList[statusKey]) {
      return OrderSubstatusList[statusKey].text;
    } else {
      return status;
    }
  }

  getOrderStatusIcon(status: string) {
    let found = false;
    let icon = './assets/images/icon/promo.svg'; //default icon

    let key: keyof typeof OrderStatusList;
    for (key in OrderStatusList) {
      let orderStatus = OrderStatusList[key];
      orderStatus.substatus.forEach((substatus) => {
        if (substatus.value === status) {
          icon = orderStatus.img;
          found = true;
        }
      });
    }

    return icon;
  }

  ngOnDestroy() {
    this.readNotificationSub?.unsubscribe();
  }
}
