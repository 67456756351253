export const environment = {
  API_URL: 'https://uat.api.shop.qminnovations.com',
  SSO_URL: 'https://uat.user.signup.myqm2u.com/',
  SSO_CALLBACK_URL: '/sso-login',
  PUBLIC_KEY: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDJK6Yiqsw6c3dKxMcdg1XxPlz4XoUTmRHTOaaw+3EtBw8ZqG+ydyqhk0pmT7MNJTIeUHLRIriznAkd8I1+xlMLm9BPWMsc7Mdxb74EWA1j+YaayMcaoW+RDPIM8pMLSTlFQUm9fcAa/r8/omFrDAqhzElkx2NSoEfOjM0KgFNPVQIDAQAB`,
  SELLER_PORTAL_URL: 'https://uat.seller.myqm2u.com/',
  IOS_DEEPLINK: 'https://uat.deeplink.myqm2u.com?dtype=home',
  // Social Media URL
  FACEBOOK_URL: 'https://myqm2u.com/download-app/',
  TWITTER_URL: 'https://myqm2u.com/download-app/',
  YOUTUBE_URL: 'https://www.youtube.com/@MyQMChannel',
  INSTAGRAM_URL: 'https://myqm2u.com/download-app/'
};
