import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SsoLoginComponent } from './components/pages/sso-login/sso-login.component';

const desktopRoutes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./components/pages/desktop-pages/desktop-pages.module').then(d => d.DesktopPagesModule)
  },
  { path: '**', redirectTo: '' },
];

const mobileRoutes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./components/pages/mobile-pages/mobile-pages.module').then(m => m.MobilePagesModule),
  },
  { path: '**', redirectTo: '' },
];

function getRoutes() {
  //Mobile Widths
  if (window.innerWidth < 768) {
    return mobileRoutes;
  } else {
    return desktopRoutes;
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(getRoutes(), { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }


/*
const routes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: 'desktop-test',
    loadChildren: () => import('./components/pages/desktop-pages/desktop-pages.module').then(d => d.DesktopPagesModule)
  },
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: 'cart',
        component: CartComponent,
        canActivate: [authGuard],
        children: [
          {
            // redirect to shopping cart on first entrance
            path: '',
            redirectTo: 'cart-list',
            pathMatch: 'full',
          },
          {
            path: 'cart-list',
            component: CartListComponent,
          },
          {
            path: 'cart-checkout/:checkout-id',
            component: CartCheckoutComponent,
          },
          {
            path: 'cart-completed',
            component: CartCompletedComponent,
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [authGuard],
        children: [
          {
            // redirect to dashboard
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'account',
            component: ProfileAccountComponent,
          },
          {
            path: 'order-list',
            component: ProfileOrderListComponent,
          },
          {
            path: 'order-list/details/:order-id',
            component: ProfileOrderDetailsComponent,
          },
          {
            path: 'order-list/shipping/:order-id',
            component: ProfileShippingDetailsComponent,
          },
          {
            path: 'dashboard/review',
            component: ProfileReviewsComponent,
          },
          {
            path: 'dashboard/review/:order-id',
            component: ProfileReviewDetailsComponent,
          },
          {
            path: 'order-list/review/:order-id',
            component: ProfileReviewDetailsComponent,
          },
          {
            path: 'order-list/return/:order-id',
            component: ProfileReturnRequestComponent,
          },
          {
            path: 'order-list/return-completed/:return-id',
            component: ProfileReturnCompletedComponent,
          },
          {
            path: 'order-list/return-details/:return-id',
            component: ProfileReturnDetailsComponent,
          },
          {
            path: 'order-list/return-method/:return-id',
            component: ProfileReturnMethodComponent,
          },
          {
            path: 'order-list/cancel/:order-id',
            component: ProfileOrderCancelComponent,
          },
          {
            path: 'order-list/cancel-completed/:cancel-id',
            component: ProfileOrderCancelCompletedComponent,
          },
          {
            path: 'order-list/cancel-details/:cancel-id',
            component: ProfileOrderCancelDetailsComponent,
          },
          {
            path: 'notification-list',
            component: ProfileNotificationListComponent,
          },
          {
            path: 'voucher',
            component: ProfileVoucherComponent,
            children: [
              {
                path: 'reward',
                component: ProfileVoucherRewardComponent,
              },
              {
                path: '',
                component: ProfileVoucherListComponent,
              },
            ],
          },
          {
            path: 'invite',
            component: ProfileInviteComponent,
          },
          {
            path: 'invite/reward',
            component: ProfileInviteRewardComponent,
          },
          {
            path: 'invite/terms',
            component: ProfileInviteTermsComponent,
          },
          {
            path: 'support',
            component: ProfileSupportComponent,
            children: [
              {
                path: 'contact',
                component: ProfileSupportContactComponent,
              },
              {
                path: '',
                component: ProfileSupportFaqComponent,
              },
            ],
          },
          {
            path: 'dashboard',
            component: ProfileDashboardComponent,
          },
        ],
      },
      {
        path: 'brand-list',
        component: BrandListComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'store-list',
        component: StoreListComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'store/:seller',
        component: StoreComponent,
        children: [
          {
            path: 'all',
            component: StoreProductListComponent,
          },
          {
            path: '',
            component: StoreHomeComponent,
          },
        ],
      },
      {
        path: 'search',
        component: SearchComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'product/:product-id',
        component: ProductComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'wishlist',
        component: WishlistComponent,
        canActivate: [authGuard],
        data: {
          reuse: true
        }
      },
      {
        path: 'minfinite',
        component: MinfiniteComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-activation',
        component: MinfiniteActivationComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-spin',
        component: MinfiniteSpinComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-prize-history',
        component: MinfinitePrizeHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-purchase-history',
        component: MinfinitePurchaseHistoryComponent,
        canActivate: [authGuard],
        children: [
          {
            path: ':history-id',
            component: MinfinitePurchaseHistoryDetailsComponent,
          },
          {
            path: '',
            component: MinfinitePurchaseHistoryListComponent,
          },
        ],
      },
      {
        path: 'minfinite-coin-history',
        component: MinfiniteCoinHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward',
        component: MrewardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-history',
        component: MrewardHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-search',
        component: MrewardSearchComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-redemption',
        component: MrewardRedemptionComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-membership',
        component: MrewardMembershipComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-tier',
        component: MrewardTierComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-mission',
        component: MrewardMissionComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-point-history',
        component: MrewardPointHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'payment',
        component: CartRetryPaymentComponent,
        canActivate: [authGuard],
      },
      {
        path: 'payment/:order-id',
        component: ProfileOrderPayComponent,
        canActivate: [authGuard],
      },
      {
        path: 'verify-pin',
        component: QmVerifyPinComponent,
        canActivate: [authGuard]
      },
      {
        path: 'review-completed',
        component: ProfileReviewCompletedComponent,
        canActivate: [authGuard],
      },
      {
        path: 'receipt/:order-id',
        component: ProfileOrderReceiptComponent,
        canActivate: [authGuard],
      },
      {
        path: 'profile/voucher/:voucher-id',
        component: VoucherComponent,
      },
      {
        path: 'promo/:promo-id',
        component: PromoComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'supply',
        component: SupplyComponent,
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
      },
      {
        path: '',
        component: HomeComponent,
        data: {
          reuse: true
        }
      },
    ],
  },
  { path: '**', redirectTo: '' },
];*/



/*
const mobileRoutes: Routes = [
  {
    path: 'sso-login',
    component: SsoLoginComponent,
  },
  {
    path: '',
    component: MobileLandingComponent,
    children: [
      {
        path: 'cart',
        component: MobileCartComponent,
        canActivate: [authGuard],
        children: [
          {
            // redirect to shopping cart on first entrance
            path: '',
            redirectTo: 'cart-list',
            pathMatch: 'full',
          },
          {
            path: 'cart-list',
            component: MobileCartListComponent,
          },
          {
            path: 'cart-checkout/:checkout-id',
            component: MobileCartCheckoutComponent,
          },
          {
            path: 'cart-completed',
            component: MobileCartCompletedComponent,
          },
        ],
      },
      {
        path: 'profile',
        component: MobileProfileComponent,
        canActivate: [authGuard],
        children: [
          {
            // redirect to dashboard
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'order-list',
            component: MobileProfileOrderListComponent,
          },
          {
            path: 'order-list/details/:order-id',
            component: MobileProfileOrderDetailsComponent,
          },
          {
            path: 'order-list/shipping/:order-id',
            component: MobileProfileShippingDetailsComponent,
          },
          {
            path: 'dashboard/review',
            component: MobileProfileReviewsComponent,
          },
          {
            path: 'dashboard/review/:order-id',
            component: MobileProfileReviewDetailsComponent,
          },
          {
            path: 'order-list/review/:order-id',
            component: MobileProfileReviewDetailsComponent,
          },
          {
            path: 'order-list/return/:order-id',
            component: MobileProfileReturnRequestComponent,
          },
          {
            path: 'order-list/return-completed/:return-id',
            component: MobileProfileReturnCompletedComponent,
          },
          {
            path: 'order-list/return-details/:return-id',
            component: MobileProfileReturnDetailsComponent,
          },
          {
            path: 'order-list/return-method/:return-id',
            component: MobileProfileReturnMethodComponent,
          },
          {
            path: 'order-list/cancel/:order-id',
            component: MobileProfileOrderCancelComponent,
          },
          {
            path: 'order-list/cancel-completed/:cancel-id',
            component: MobileProfileOrderCancelCompletedComponent,
          },
          {
            path: 'order-list/cancel-details/:cancel-id',
            component: MobileProfileOrderCancelDetailsComponent,
          },
          {
            path: 'notification-list',
            component: MobileProfileNotificationListComponent,
          },
          {
            path: 'voucher/:voucher-id',
            component: MobileVoucherComponent
          },
          {
            path: 'voucher',
            component: MobileProfileVoucherComponent,
            children: [
              {
                path: 'reward',
                component: ProfileVoucherRewardComponent,
              },
              {
                path: '',
                component: MobileProfileVoucherListComponent,
              },
            ],
          },
          {
            path: 'invite',
            component: MobileProfileInviteComponent,
          },
          {
            path: 'invite/reward',
            component: MobileProfileInviteRewardComponent,
          },
          {
            path: 'invite/terms',
            component: MobileProfileInviteTermsComponent,
          },
          {
            path: 'support',
            component: MobileProfileSupportComponent,
            children: [
              {
                path: 'contact',
                component: MobileProfileSupportContactComponent,
              },
              {
                path: '',
                component: MobileProfileSupportFaqComponent,
              },
            ],
          },
          {
            path: 'dashboard',
            component: MobileProfileDashboardComponent,
          },
        ],
      },
      {
        path: 'profile/account',
        component: MobileProfileAccountComponent,
      },
      {
        path: 'notification-list',
        component: MobileProfileNotificationListComponent,
        canActivate: [authGuard]
      },
      {
        path: 'brand-list',
        component: MobileBrandListComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'store-list',
        component: MobileStoreListComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'store/:seller',
        component: MobileStoreComponent,
        children: [
          {
            path: 'all',
            component: MobileStoreProductListComponent,
            data: {
              reuse: true
            }
          },
          {
            path: '',
            component: MobileStoreHomeComponent,
            data: {
              reuse: true
            }
          },
        ],
      },
      {
        path: 'search',
        component: MobileCategoryListComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'search/details',
        component: MobileSearchComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'product/:product-id',
        component: MobileProductComponent,
        data: {
          reuse: true
        }
      },
      {
        path: 'product/review-list/:product-id',
        component: MobileProductReviewListComponent,
      },
      {
        path: 'wishlist',
        component: MobileWishlistComponent,
        canActivate: [authGuard],
        data: {
          reuse: true
        }
      },
      {
        path: 'minfinite',
        component: MinfiniteComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-activation',
        component: MinfiniteActivationComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-spin',
        component: MinfiniteSpinComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-prize-history',
        component: MinfinitePrizeHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'minfinite-purchase-history',
        component: MinfinitePurchaseHistoryComponent,
        canActivate: [authGuard],
        children: [
          {
            path: ':history-id',
            component: MinfinitePurchaseHistoryDetailsComponent,
          },
          {
            path: '',
            component: MinfinitePurchaseHistoryListComponent,
          },
        ],
      },
      {
        path: 'minfinite-coin-history',
        component: MinfiniteCoinHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward',
        component: MrewardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-history',
        component: MrewardHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-search',
        component: MrewardSearchComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-redemption',
        component: MrewardRedemptionComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-membership',
        component: MrewardMembershipComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-tier',
        component: MrewardTierComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-mission',
        component: MrewardMissionComponent,
        canActivate: [authGuard],
      },
      {
        path: 'mreward-point-history',
        component: MrewardPointHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'payment',
        component: MobileCartRetryPaymentComponent,
        canActivate: [authGuard],
      },
      {
        path: 'payment/:order-id',
        component: MobileProfileOrderPayComponent,
        canActivate: [authGuard],
      },
      {
        path: 'verify-pin',
        component: QmVerifyPinComponent,
        canActivate: [authGuard]
      },
      {
        path: 'review-completed',
        component: MobileProfileReviewCompletedComponent,
        canActivate: [authGuard],
      },
      {
        path: 'receipt/:order-id',
        component: MobileProfileOrderReceiptComponent,
        canActivate: [authGuard],
      },
      {
        path: 'voucher/:voucher-id',
        component: MobileVoucherComponent,
      },
      {
        path: 'promo/:promo-id',
        component: MobilePromoComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'support',
        component: MobileSupportComponent,
        children: [
          {
            path: 'contact',
            component: MobileProfileSupportContactComponent,
          },
          {
            path: '',
            component: MobileProfileSupportFaqComponent,
          },
        ],
      },
      {
        path: 'supply',
        component: SupplyComponent,
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
      },
      {
        path: '',
        component: MobileHomeComponent,
        data: {
          reuse: true
        }
      },
    ],
  },
  { path: '**', redirectTo: '' },
];
*/