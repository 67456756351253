import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselModule } from './custom-carousel/custom-carousel.component';
import { CustomOverlayPanelModule } from './custom-overlaypanel/custom-overlaypanel.component';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CustomCarouselModule,
    CustomOverlayPanelModule
  ],
  exports: [
    CustomCarouselModule,
    CustomOverlayPanelModule
  ]
})
export class CustomModule { }
