import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyQmRequest } from '../shared/models/myqm-request.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {
  Notification,
  NotificationCount,
} from '../shared/models/notification.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private apiUrl = environment.API_URL;

  private notificationCount: NotificationCount;
  private notificationCountSubject: BehaviorSubject<NotificationCount>;
  public notificationCount$: Observable<NotificationCount>;

  getNotificationsCount() {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/notifications/getNotReadCount`,
        {}
      )
      .pipe(
        map((res) => {
          this.notificationCountSubject.next(res.data);
          return res.data as NotificationCount;
        })
      );
  }

  getNotificationsList(searchCriteria: {
    pageNo: number;
    type?: string;
    orderStatus?: string;
  }) {
    return this.http
      .post<MyQmRequest>(
        `${this.apiUrl}/web/v1/notifications/getList`,
        searchCriteria
      )
      .pipe(
        map((res) => {
          return res.data as {
            list: Notification[];
            count: number;
          };
        })
      );
  }

  getNotificationContent(id: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/notifications/getContent`, {
        id: id,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  readNotification(id: number) {
    return this.http
      .post<MyQmRequest>(`${this.apiUrl}/web/v1/notifications/read`, {
        id: id,
      })
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  constructor(private http: HttpClient) {
    this.notificationCount = {
      noReadPromo: 0,
      noReadChats: 0,
      noReadOrder: 0,
      noReadToReceive: 0,
      noReadAll: 0,
      noReadToReview: 0,
      noReadToShip: 0,
      noReadReturnCancellations: 0,
    };
    this.notificationCountSubject = new BehaviorSubject<NotificationCount>(
      this.notificationCount
    );
    this.notificationCount$ = this.notificationCountSubject.asObservable();
  }
}
